<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar
        :src="
          user?.profile_picture
            ? 'https://api.medicascrm.com/storage/' + user.profile_picture
            : './user.svg'
        "
        size="md"
        id="userSvg"
      />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0 overflow-hidden rounded-top-4">
      <CDropdownHeader
        component="h6"
        class="fw-bold py-2 text-white"
        style="background-color: #df7e8f"
      >
        Opciones
      </CDropdownHeader>
      <CDropdownItem @click="profile()">
        <CIcon icon="cil-user" /> Mi perfil
      </CDropdownItem>
      <CDropdownItem @click="logout()">
        <CIcon icon="cil-lock-locked" /> Cerrar Session
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import {
  CAvatar,
  CDropdownItem,
  CDropdownMenu,
  CDropdownHeader,
  CDropdownToggle,
  CDropdown,
} from '@coreui/vue'
import avatar from '@/assets/images/avatars/8.jpg'
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    CAvatar,
    CDropdownItem,
    CDropdownMenu,
    CDropdownHeader,
    CDropdownToggle,
    CDropdown,
  },
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      avatar,
      itemsCount: 42,
    }
  },
  methods: {
    ...mapMutations(['updateToken', 'updateUser']),
    logout() {
      this.$router.push('/login')
      this.updateUser(null)
      this.updateToken(null)
    },
    profile() {
      this.$router.push('/perfil')
    },
  },
}
</script>
<style scoped>
.nav-item {
  cursor: pointer;
}
</style>
