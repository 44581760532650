import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    token: null,
    user: {},
    rol: null,
    doctorSelected: null,
    patientSelected: null,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    updateToken(state, token) {
      state.token = token
    },
    updateUser(state, user) {
      state.user = user
      if (user) {
        state.rol = user.roles[0].name
      }
    },
    setDoctoraSelected(state, doctor) {
      state.doctorSelected = doctor
    },
    setPatientSelected(state, patient) {
      state.patientSelected = patient
    },
  },
  actions: {},
  modules: {},
  getters: {
    isAuthenticated: (state) => !!state.token,
  },
  plugins: [new VuexPersistence().plugin],
})
