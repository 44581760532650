export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    // icon: 'cil-speedometer',
    type: 'doctor',
  },
  {
    component: 'CNavItem',
    name: 'Horarios',
    to: '/horarios',
    // icon: 'cil-task',
    type: 'doctor',
  },
  {
    component: 'CNavItem',
    name: 'Agenda',
    to: '/agenda',
    // icon: 'cil-calendar',
    type: 'doctor',
  },
  {
    component: 'CNavItem',
    name: 'Aliadas',
    to: '/doctoras',
    // icon: 'cil-hospital',
    type: 'doctor',
  },
  {
    component: 'CNavItem',
    name: 'Pacientes',
    to: '/pacientes',
    // icon: 'cil-clipboard',
    type: 'doctor',
  },
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    // icon: 'cil-speedometer',
    type: 'admin',
  },
  {
    component: 'CNavItem',
    name: 'Citas',
    to: '/citas',
    // icon: 'cil-calendar',
    type: 'admin',
  },
  {
    component: 'CNavItem',
    name: 'Aliadas',
    to: '/doctoras',
    // icon: 'cil-hospital',
    type: 'admin',
  },
  {
    component: 'CNavItem',
    name: 'Pacientes',
    to: '/pacientes',
    // icon: 'cil-clipboard',
    type: 'admin',
  },
  {
    component: 'CNavItem',
    name: 'Agenda',
    to: '/calendario',
    // icon: 'cil-calendar',
    type: 'patient',
  },
  {
    component: 'CNavItem',
    name: 'Agendar Cita',
    to: '/cita',
    // icon: 'cil-calendar-check',
    type: 'patient',
  },
]
