import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store'

function getUserType() {
  if (store.state.rol == null) {
    return 'guest'
  }
  return store.state.rol
}

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: DefaultLayout,
    beforeEnter: ifAuthenticated,
    redirect: '/dashboard',
    meta: { requiresAuth: true, adminOnly: true },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/DashboardView.vue'
          ),
      },
      {
        path: '/calendario',
        name: 'calendar',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/CalendarViewPatient.vue'
          ),
      },
      {
        path: '/perfil',
        name: 'perfil',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/InfoView.vue'),
      },
      {
        path: '/citas',
        name: 'Información de citas',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/ConsultasView.vue'
          ),
      },
      {
        path: '/horarios',
        name: 'Horarios',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/SchedulesView.vue'
          ),
      },
      {
        path: '/cita',
        name: 'appointment',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/AppoinmentView.vue'
          ),
      },
      {
        path: '/agenda',
        name: 'Agenda',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/CalendarView.vue'
          ),
      },
      {
        path: '/pacientes',
        name: 'Pacientes',
        children: [
          {
            path: '',
            name: 'Lista de pacientes',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/views/clients/ClientsView.vue'
              ),
          },
          {
            path: ':id',
            name: 'paciente',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/views/clients/ClientView.vue'
              ),
          },
        ],
      },
      {
        path: '/doctoras',
        name: 'Doctoras',
        children: [
          {
            path: '',
            name: 'Lista de doctoras',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/views/doctors/DoctorasView.vue'
              ),
          },
          {
            path: ':id',
            name: 'Información del doctor',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ '@/views/doctors/DoctoraView.vue'
              ),
          },
        ],
      },
    ],
  },

  {
    path: '/usuario',
    name: 'usuario',
    meta: { requiresAuth: false, adminOnly: false },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/UserView.vue'),
  },

  {
    path: '/login',
    name: 'Login',
    meta: { requiresAuth: false, adminOnly: false },
    beforeEnter: ifNotAuthenticated,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/LoginView.vue'),
  },
  {
    path: '/recuperar',
    name: 'password',
    meta: { requiresAuth: false, adminOnly: false },
    beforeEnter: ifNotAuthenticated,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/RecoveryView.vue'),
  },
  {
    path: '/recovery-password',
    name: 'recovery',
    meta: { requiresAuth: false, adminOnly: false },
    beforeEnter: ifNotAuthenticated,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/ResetView.vue'),
  },
  {
    path: '/registro',
    name: 'Registro',
    meta: { requiresAuth: false, adminOnly: false },
    beforeEnter: ifNotAuthenticated,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/RegisterView.vue'),
  },
  {
    path: '/aviso_de_privacidad',
    name: 'privacy',
    meta: { requiresAuth: false, adminOnly: false },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/PrivacyView.vue'),
  },
  {
    path: '/terminos_y_condiciones',
    name: 'terms',
    meta: { requiresAuth: false, adminOnly: false },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/TermsView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const userType = getUserType()

  if (to.meta.requiresAuth) {
    if ((userType === 'doctor' || userType === 'admin') && to.meta.adminOnly) {
      next() // Permitir a los admins
    } else if (userType === 'patient' && to.meta.adminOnly) {
      next() // Permitir a los usuarios normales
    } else {
      next('/login') // Redirigir a los no autorizados
    }
  } else {
    next() // Continuar para rutas que no requieren autenticación
  }
})

export default router
