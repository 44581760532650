<template>
  <div :style="bg">
    <router-view />
    <div v-show="showAdn">
      <img src="./assets/images/entrelazado-beige.png" class="adn" id="adn" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'App',
  computed: {
    showAdn() {
      return (
        this.$route.name !== 'Login' &&
        this.$route.name !== 'password' &&
        this.$route.name !== 'recovery' &&
        this.$route.name !== 'Registro'
      )
    },
    bg() {
      // check route name
      let bgr = ''
      if (this.$route.name === 'Dashboard' || this.$route.name === 'calendar') {
        bgr = 'background-color: #52616D'
      }
      if (
        this.$route.name === 'perfil' ||
        this.$route.name === 'Agenda' ||
        this.$route.name === 'appointment'
      ) {
        bgr = 'background-color: #6E816B'
      }

      if (this.$route.name === 'Información de citas') {
        bgr = 'background-color: #698269'
      }

      if (this.$route.name === 'Lista de doctoras') {
        bgr = 'background-color: #495461'
      }

      if (this.$route.name === 'Información del doctor') {
        bgr = 'background-color: #5A5A5A'
      }
      if (
        this.$route.name === 'Horarios' ||
        this.$route.name === 'Lista de pacientes' ||
        this.$route.name === 'paciente'
      ) {
        bgr = 'background-color: #D0828F'
      }
      document.body.style = bgr
      return bgr
    },
  },
}
</script>
<style lang="scss">
@import 'styles/style';

@font-face {
  font-family: 'Comforta';
  src: url(./assets/fonts/Comforta.ttf);
}

body {
  font-family: 'Comforta';
}
.form-control:focus {
  outline: 0;
  box-shadow: none;
}
.pointer {
  cursor: pointer;
}
.sidebar-nav .nav-link {
  justify-content: center;
}
.header {
  background-color: #ffffff00 !important;
  --cui-header-border: var(--cui-border-width) solid #ffffff00;
}
.header.header-sticky {
  position: relative !important;
}
.sidebar {
  background-color: #ffffff00 !important;
}
.sidebar-brand {
  background-color: #ffffff00 !important;
}
.sidebar-toggler {
  display: none !important;
}
.adn {
  position: relative;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  opacity: 0.3;
}
.sidebar-nav .nav-link.active {
  background: #df7e8f;
  border-radius: 0px 20px 20px 0px;
}
.header-toggler {
  color: white;
}
.header-toggler:hover {
  color: white;
}

.vuecal--month-view .vuecal__cell {
  height: 80px !important;
}

.vuecal--month-view .vuecal__cell-content {
  justify-content: flex-start;
  height: 100%;
  align-items: flex-end;
}

.vuecal--month-view .vuecal__cell-date {
  padding: 4px;
}
.vuecal--month-view .vuecal__no-event {
  display: none;
}
.vuecal__menu {
  color: white;
}
.vuecal__body {
  background: white;
  border-radius: 0px 0px 30px 30px;
}
.vuecal__bg {
  background-color: #fff !important;
}
.vuecal__view-btn--highlighted
  .vuecal__today-btn--highlighted
  .vuecal__arrow--highlighted
  .vuecal__cell--highlighted
  .vuecal__cell-split--highlighted {
  background-color: #df7e8f !important;
}
.vuecal__header button {
  color: white;
}
.vuecal__weekdays-headings {
  background: white;
  color: black;
}
.table-responsive {
  border-radius: 20px;
}
.border {
  border: #d8dbe000 !important;
}
.table-secondary {
  background-color: #f4f3eb00 !important;
  --cui-table-bg: #e2e5e900;
  --cui-table-color: rgb(255 255 255 / 95%);
}
.card {
  border-radius: 20px;
}
@media (max-width: 768px) {
  .sidebar {
    background-color: #495461 !important;
  }
}
</style>
